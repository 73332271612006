.carousel-item {
    height: calc(100vh - 158.50px);
    // height: 65vh;
    // min-height: 350px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .carousel-areas-atuacao {
    height: 400px;
    // height: 65vh;
    // min-height: 350px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .carousel-titulo {
    background: linear-gradient(to right, transparent, rgba(0,0,0,0.8), transparent);
    font-size: 36px;
    font-weight: bold;
    padding: 20px;
  }